import * as Sentry from "@sentry/astro";
import { BrowserTracing } from "@sentry/astro";
import { httpClientIntegration } from "@sentry/integrations";
import { createClient } from "./src/lib/supabaseClient";

const sbClient = createClient();

Sentry.init({
  dsn: "https://d251ad2641e464414977c85b537d56ce@o190059.ingest.sentry.io/4506667988615168",
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    Sentry.replayIntegration({
      // maskAllText: true,
      blockAllMedia: true,
    }),
    new BrowserTracing({
      tracePropagationTargets: [/\//, "my-api-domain.com"],
    }),
    httpClientIntegration(),
  ],
  release: INTIO_RELEASE,
  environment: import.meta.env.DEV ? "dev" : "production",
});

sbClient.auth.onAuthStateChange((ev, session) => {
  if (session) {
    Sentry.setUser({ email: session.user.email, id: session.user.id });
  }
});
